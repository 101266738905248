
          @import "src/sass/global";
        
.pageHeader {
  height: 4rem;
}

.appNavBarContainer {
  align-items: center;
  background-color: white;
  box-shadow: $box-shadow-header-row;
  display: flex;
  justify-content: space-between;
  height: 4rem;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  z-index: 800;
  width: 100%;

  @include media('>=tablet') {
    padding: - $layout-base-padding;
  }
}

.navigationWrapper {
  align-items: center;
  display: flex;
  flex: 1 1 90%;
  justify-content: flex-end;

  @include media('>=tablet') {
    gap: $layout-base-gap-medium;
  }
}
