
          @import "src/sass/global";
        
.sectionTitle {
  font-size: $typography-size-default;
  font-weight: $typography-style-bold;
  justify-content: space-between;
  margin: 0;
  padding-top: $layout-narrow-padding;
}

.subNavigation {
  display: flex;
  flex-direction: column;
  gap: $layout-default-padding;

  a:link {
    color: $color-neutral-70;
    display: block;
  }

  .active {
    background-color: $color-blue-20;
    border-radius: $border-radius-default;
    padding: $layout-narrow-padding;

    a {
      margin: initial;
    }
  }
}
