
          @import "src/sass/global";
        
.noteCard {
  border-radius: $border-radius-default;
  margin: $layout-base-padding 0;
  padding: $layout-base-padding;
  position: relative;

  :global .notecard-title {
    margin-bottom: 0;
  }
}

.general {
  background-color: $color-blue-20;
}

.info {
  background-color: $color-yellow-20;
}

.notecardCloseButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
