
          @import "src/sass/global";
        
.form {
  input, textarea, select {
    width: 100%;
  }

  :global .field {
    margin-bottom: $layout-default-padding;
    p:first-child {
      margin-top: 0;
    }
  }
}
