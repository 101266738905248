
          @import "src/sass/global";
        
.step {
  align-items: center;
  background-color: $color-neutral-inverted;
  border-radius: 50%;
  box-shadow: $box-shadow-step-button;
  color: $color-blue-50;
  display: flex;
  font-size: $typography-size-large;
  font-weight: $typography-style-bold;
  height: 2rem;
  justify-content: center;
  padding: $layout-base-padding;
  width: 2rem;

  @include media(">=tablet") {
    height: 4rem;
    width: 4rem;
  }
}

.stepActive {
  background-color: $color-blue-50;
  color: $color-neutral-inverted;
}
