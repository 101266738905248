
          @import "src/sass/global";
        
li.sectionTitle {
  justify-content: space-between;
  margin: 0;
  padding: 0;

  span {
    font-weight: bold;
  }
}
