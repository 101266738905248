
          @import "src/sass/global";
        
.buttonWrapper {
  width: 100%;

  button {
    margin: 0 $layout-base-gap;

    &:last-child {
      margin-right: 0;
    }
  }
}
