
          @import "src/sass/global";
        
.uploadPreviewContainer {
  display: flex;
  flex-direction: column;
  gap: $layout-base-gap-medium;
  margin: $layout-base-gap-wide 0;

  @include media('>=desktop') {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.uploadPreview {
  position: relative;

  @include media('>=desktop') {
    flex: 1 1 40%;
  }

  img,
  video,
  object {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  figure {
    margin: 0;
    padding: 0;
    height: 220px;
  }

  textarea {
    margin-top: 10px;
    width: 100%;
    height: rem(83px);
  }

  button {
    display: block;
    margin: $layout-base-gap auto;
  }
}
