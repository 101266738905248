
          @import "src/sass/global";
        
.pageFooter {
  background-color: $blue;
  margin-top: $layout-base-gap-wide;
  padding: $layout-base-padding;
}

.pageFooterSectionGroup {
  margin-bottom: $layout-base-gap-wide;
  justify-content: space-evenly;
  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
    gap: $layout-base-gap-medium;

    ul {
      margin-bottom: initial;
    }
  }
}

.pageFooterSection {
  display: block;
  flex-basis: fit-content;
  @include media('>=tablet') {
    gap: $layout-base-gap-medium;
  }
}

.pageFooterList {
  margin-bottom: $layout-base-gap-medium;
  li > a {
    color: $color-neutral-inverted;
  }
  
  @include media('>=tablet') {
    margin-bottom: initial;
  }
}
 
.pageFooterList:before{
  content:attr(aria-label);
  font-weight: $typography-style-medium-bold;
  color: $color-neutral-inverted;
  text-transform: uppercase;
}

.pageFooterContactInfo {
  display: flex;
  align-items: center;
  gap: $layout-narrow-gap;
}

.pageFooterSocialLinkGroup {
  display: flex;
  justify-content: center;
  gap: $layout-base-gap-medium;
}

.pageFooterSocialLink > svg {
  color: $color-neutral-inverted;
  height: 2rem;
  width: 2rem;
}

.pageFooterBottomLinks {
  display: flex;
  flex-direction: column;
  gap: $layout-base-gap;
  margin: 0 auto;
  max-width: max-content;
  text-align: center;

  @include media('>=tablet') {
    flex-direction: row;
    justify-content: center;
  }

  a {
    color: $color-neutral-inverted;
    display: block;

    @include media('>=tablet') {
      &::after {
        color: $desire;
        content: '|';
        display: inline-block;
        font-weight: $typography-style-medium-bold;
        margin-left: 1rem;
      }
    }
  }

  li:last-child a::after {
    display: none;
  }
}
