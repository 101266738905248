
          @import "src/sass/global";
        
.avatar {
  position: relative;
  pointer-events: none;

  @include media(">=desktop") {
    margin: $layout-default-padding auto;
    max-width: 20rem;
    flex: 1;
    flex-basis: 20rem;
    display: flex;
    align-content: center;
  }

  img {
    display: block;
    height: 80px;
    margin: 0 auto;
    width: 80px;

    @include media(">=desktop") {
      width: 162px;
      height: 162px;
    }
  }

  :global .btn.red-icon {
    position: absolute;
    top: 0;
    right: 0;

    @include media(">=desktop") {
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}
