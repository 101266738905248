
          @import "src/sass/global";
        
@import '../../../../atoms/ServicesIcons.module.scss';

.services {
  @include media('>=desktop') {
    display: flex;
    flex-wrap: wrap;
    gap: $layout-base-gap-medium;
    justify-content: space-between;
  }

  li {
    margin-bottom: $layout-base-gap-medium;

    @include media('>=desktop') {
      margin-bottom: initial;
    }
  }

  img {
    max-width: 45px;
    max-height: 45px;
  }
}

.serviceWrapper {
  background-color: $color-gray-05;
  border-radius: $border-radius-default;
  flex: 1 1 45%;
  padding: $layout-default-padding;
}

.serviceHeader {
  align-items: center;
  display: flex;
}

.servivesSubList {
  list-style: disc;
  margin-top: $layout-narrow-padding;

  li {
    margin-bottom: 0;
  }
}

.textArea {
  color: $color-blue-70;
  display: block;
  font-weight: $typography-style-bold;
  margin-top: $layout-base-gap-medium;

  :global .field {
    margin: 0;
    margin-top: $layout-base-gap;
  }
}

.characterCounter {
  display: block;
  font-weight: normal;
  margin-top: $layout-narrow-gap;
}
