
          @import "src/sass/global";
        
.alert {
  display: flex;
  align-items: center;
}

.unread {
  align-items: center;
  display: inline-flex;
  margin-left: $layout-narrow-gap;

  &::after {
    background: $desire;
    content: '';
    display: inline-block;
    border-radius: 100%;
    height: 5px;
    margin-left: $layout-narrow-gap;
    width: 5px;
  }
}

.message {
  display: inline-block;
  strong {
    margin-right: 10px;
  }
}

.avatar {
  height: rem(36px);
  width: rem(36px);
  display: flex;
  justify-content: center;
  background-color: $color-purple-40;
  border-radius: 100%;
  overflow: hidden;
  align-content: center;
  margin-right: 18px;

  span {
    color: white;
    vertical-align: middle;
    height: rem(36px);
    line-height: rem(36px);
  }
}

.link {
  p {
    margin: 0;
  }
}

.timestamp {
  display: flex;
  align-items: center;
}
