
          @import "src/sass/global";
        
.overlay {
  align-items: center;
  background-color: $color-dialog-backdrop;
  display: flex;
  inset: 0;
  position: fixed;
  z-index: 800;

  @media (prefers-reduced-motion: no-preference) {
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
}

.modal {
  background-color: $color-dialog-background;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5rem;
  z-index: $z-index-bring-to-front;

  @include media('>=tablet') {
    border-radius: 6px;
    margin: 1.75rem auto;
    height: auto;
    max-height: 90vh;
    max-width: $layout-max-modal-width;
    padding-bottom: initial;
    position: relative;
    width: 60vw;
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  h1,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
}

.modalMainContent {
  padding: $layout-base-padding;
}
