
          @import "src/sass/global";
        
.resultsList {
  background-color: $color-neutral-inverted;
  padding: $layout-default-padding;
  position: absolute;
  width: 100%;
}

.resultsListItem {
  text-align: left;
  width: 100%;
}
