
          @import "src/sass/global";
        
.footerButtonRow {
  background-color: $color-gray-05;
  border-top: 1px solid $color-gray-30;
  bottom: 0;
  box-shadow: $box-shadow-button-row;
  display: flex;
  align-items: center;
  gap: $layout-default-padding;
  justify-content: flex-end;
  left: 0;
  padding: $layout-default-padding;
  position: fixed;
  text-align: right;
  width: 100%;

  @include media(">=tablet") {
    bottom: initial;
    left: initial;
    position: initial;
  }
}

.footerButtonRowStacked {
  display: block;
}
