
          @import "src/sass/global";
        
.field {
  padding: $layout-default-padding 0;

  label {
    align-items: center;
    display: flex;
    font-weight: $typography-style-bold;
    gap: $layout-default-padding;
    justify-content: space-between;

    > span {
      flex: 1 1 60%;
    }

    .select {
      flex: 1 1 40%;
    }

    small {
      display: inline-block;
      margin-top: 1em;
    }
  }

  :global .select {
    min-width: 91px;
  }

  @include hr;
}

.standardField {
  flex: 1 1 100%;
  margin-top: $layout-base-padding;

  @include media('>=tablet') {
    flex-basis: 50%;
  }

  label {
    font-weight: $typography-style-bold;
  }

  select {
    display: block;
    margin-top: 0;
    width: 100%;
  }
}

.fieldNotSep {
  border: none;
}

.fieldLabelText {
  color: $blue;
  font-weight: $typography-style-bold;
  margin: $layout-default-padding 0;
}

.buttonRow {
  padding: 26px 56px;
  background-color: $gray-150;
  margin: 0 -15px -30px -15px;
  display: flex;
  justify-content: space-between;
}

.singleButtonRow {
  justify-content: flex-end;
}

.textArea {
  margin: 0;

  textarea {
    min-height: 2em;
    width: 100%;
    border-radius: 10px;
  }
}

.extendedLabel {
  max-width: 70%;
}
