
          @import "src/sass/global";
        
.sameAddressLabel {
  background-color: $color-neutral-inverted;
  padding: $layout-default-padding;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    @include media(">=tablet") {
      margin-left: 37px;
    }
  }
}

.userAddress {
  padding-left: $layout-default-padding;
}

.editAddress {
  margin: $layout-default-padding 0;
}

.primaryContact {
  display: flex;
  gap: $layout-base-gap;
  flex-direction: column;
  @include media(">=tablet") {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    label {
      flex: 1 1 auto;
    }
    
    select {
      flex: 1 1 30%;
    }
  }
}
