
          @import "src/sass/global";
        
.modal {
  @include media('>=tablet') {
    max-width: 560px;
  }
}

.login {
  input {
    width: 100%;
    min-width: 320px;
  }

  :global .field {
    margin-left: 0;
    margin-right: 0;
  }
}

.loginFooter {
  display: flex;
  justify-content: space-between;

  :global .btn.lock {
    display: flex;
    gap: $layout-base-gap;
    justify-content: space-between;
  }

  :global .btn.lock svg {
    left: initial;
    position: relative;
    top: initial;
  }
}

.loginFooterButtonGroup {
  display: flex;
  gap: $layout-base-gap;
}

.hideField {
  display: none;
}

.providers {
  display: flex;
  flex-direction: column;

  button {
    flex: 1;
    width: unset;
    max-width: unset;
    border: 1px solid black;
    background: white;
    color: #555d71;
    font-weight: bold;

    &:focus,
    &:hover {
      background: $gray-100;
    }
  }
}

.or {
  border: none;
  border-top: 1px solid $black;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
  margin: 3em 0;
}

.or:after {
  background: white;
  content: ' Or ';
  padding: 0 6px;
  position: relative;
  top: -1em;
}

.chooseRoleButton {
  width: 100%;
}

.birthYearField {
  select {
    display: block;
    margin-top: $layout-default-padding;
    min-width: 100%;
  }
}

.emailSignUpForm {
  display: flex;
  flex-direction: column;
  gap: $layout-base-gap;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: $layout-base-gap;
}

.verificationWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  input {
    display: block;
    position: absolute;
    left: -9999px;
  }
}

.verificationContainer {
}

.verificationCharacterSelected {
  position: relative;
  &:empty::after {
    content: "";
    width: 1px;
    height: 30px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
