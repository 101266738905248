
          @import "src/sass/global";
        
.toggle {
  color: $black;
  position: relative;
}

.dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1.5em;
  top: 4.2em;
  width: 90%;
  z-index: $z-index-bring-to-front;
  @include elevated;
  border-radius: 6px;
  overflow: hidden;
  height: fit-content;
  @include grow-down;

  @include media('>=tablet') {
    width: 400px;
  }

  ul {
    padding: rem(12px) rem(16px);

    li {
      margin-bottom: $layout-base-gap;
    }

    li:last-child a {
      margin-bottom: 0;
    }
  }
}

.header {
  background-color: $gray-100;
  padding: rem(12px) rem(16px);
  display: flex;
  justify-content: space-between;
}

.unreadCount {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 15px;
  width: 11px;
  height: 11px;
  background-color: red;
  border-radius: 100%;
}
