
          @import "src/sass/global";
        
.visuals {
  margin: $layout-base-gap 0;

  input[type='file'] {
    display: none;
  }
}

.footnote {
  display: block;
}
