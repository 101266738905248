
          @import "src/sass/global";
        
.mainMenuToggle {
  border: 0;
  background: none;
  color: $black;

  svg {
    height: 20px;
    pointer-events: none;
    width: 5px;
  }
}

.joinNetworkToggle {
  border: 0;
  background: none;
  color: $blue;
}

.mainNav {
  align-items: center;
  display: flex;
  gap: $layout-base-gap;

  a {
    color: $blue;
    display: block;
  }

  li {
    display: inline-flex;
    align-items: center;
    button {
      color: $blue;
    }
  }
  li.separator {
    border-top: $border-desire-thin;
    margin-top: $layout-default-padding;
    padding-top: $layout-default-padding;
  }
}

.collapseMenu {
  display: none;
  @include grow-down;

  a {
    color: $blue;
    display: block;
  }

  li {
    padding: $layout-narrow-padding;
  }
  li.separator {
    border-top: $border-desire-thin;
    margin-top: $layout-default-padding;
    padding-top: $layout-default-padding;
  }
}

.collapseMenu:global(.show) {
  background-color: $color-neutral-inverted;
  border-radius: $border-radius-default;
  display: flex;
  flex-direction: column;
  padding: $layout-default-padding;
  position: absolute;
  right: 1.5em;
  top: 3.2em;
  width: 60%;
  width: max-content;
  z-index: $z-index-bring-to-front;

  li:last-child a {
    margin-bottom: 0;
  }
}

/* when we are not showing the menu for an authenticated user,
   we can afford some extra space between the nav items */
.defaultMenu {
  gap: $layout-base-gap-medium;
}

.authProfileMenu {
  .mainNav {
    gap: 0;
  }

  :global .show {
    top: 4.2em;
  }
}

li.base {
  @include media('<tablet') {
    display: none;
  }
}

.avatar {
  margin: 0;
  :global .avatar {
    height: 2.5rem;
    pointer-events: none;
    width: 2.5rem;
    border-width: 3px;
  }
}
