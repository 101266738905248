
          @import "src/sass/global";
        
.projectStage {
  margin: 0 auto;
  width: 100%;

  @include media(">=tablet") {
    display: flex;
    flex-wrap: wrap;
    width: rem(600px);
  }
}

.stageOption {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: $layout-base-padding;

  @include media(">=tablet") {
    flex: 1 1 45%;
    max-width: rem(300px);
  }

  button {
    margin-bottom: 26px;
  }

  &:hover {
    @include elevated;
    background-color: white;
    cursor: pointer;
  }

  label {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

.dateField {
  margin: $layout-base-padding auto 0;
  max-width: $layout-max-input-length;
}
