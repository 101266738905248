
          @import "src/sass/global";
        
.form {
  display: flex;
  width: 100%;
}

.button {
  margin-bottom: 1em;

  :global .icon {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;

    &.microsoft {
      background: url('/static/sso/btn_microsoft_light_normal.svg') transparent 50% 50% no-repeat;
    }
    &.google {
      background: url('/static/sso/btn_google_light_normal.svg') transparent 50% 50% no-repeat;
    }
  }
}
