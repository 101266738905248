
          @import "src/sass/global";
        
.add {
  display: flex;
  align-items: center;
}

.customOptionBtn {
  color: $blue;

  &:hover {
    color: $black;
  }
}

.customOptionContainer {
  align-items: center;
  display: flex;

  label {
    align-items: center;
    display: flex;
  }

  .deleteIcon {
    height: 1.3rem;
    width: 1.3rem;
  }
}
