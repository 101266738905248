
          @import "src/sass/global";
        
.activated {
  margin: 0 auto;
  max-width: max-content;
  padding: $layout-default-padding;

  select {
    display: block;
    width: 100%;
  }
}

.skipButton {
  display: initial;
}

.upload {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 40px 0;
  display: flex;
  margin-bottom: 1em;

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 115px;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  img {
    width: 115px;
    height: 115px;
    border-radius: 100%;
  }

  :global .extended-label {
    flex: 1;
    text-align: right;
  }
}

.ghost {
  width: 115px;
  height: 115px;
  background-color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  svg {
    color: white;
  }
}

body .paymentStep2 {
  margin-top: 1em;
  color: $blue;
}
