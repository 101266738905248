
          @import "src/sass/global";
        
.modal {
  background-color: $color-dialog-backdrop;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-bring-to-front;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
}

.alert {
  z-index: 2000;
  .inner {
    min-width: 400px;
  }
}

.overlay {
  background-color: #4a5266cc;
  position: fixed;
  inset: 0;
  z-index: 800;

  @media (prefers-reduced-motion: no-preference) {
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
}

.content {
  padding: $layout-base-gap-medium;
}

.footer {
  text-align: right;
  padding: 0 15px 15px 15px;
}

.contentNoTitle {
  padding-top: 15px;
}

div.contentBare {
  padding: 0;
}

.contentNoFooter {
  padding-bottom: 30px;
}

.modalHeader {
  align-items: center;
  background-color: $color-gray-20;
  display: flex;
  justify-content: space-between;
  padding: $layout-default-padding;
}

.modalTitle {
  color: $blue;
  font-weight: $typography-style-bold;
}

.title {
  color: $blue;
  font-size: $typography-size-large;
  margin-bottom: 0;
}

.inner {
  background-color: $color-dialog-background;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5rem;

  @include media(">=tablet") {
    border-radius: 6px;
    margin: 1.75rem auto;
    height: auto;
    max-height: 90vh;
    max-width: 80vw;
    padding-bottom: initial;
    position: relative;
    width: fit-content;

    @media (prefers-reduced-motion: no-preference) {
      animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
  }
}

// ----- theme

.confirm {
  .content {
    max-width: 800px;
    @include media(">=tablet") {
      min-width: 500px;
    }
  }
}

.confirm,
.alert {
  .title {
    text-align: center;
    background-color: $gray-150;
  }
  .footer {
    padding: 30px 70px;
    background-color: $gray-150;
    display: flex;
    white-space: nowrap;
    justify-content: space-between;

    button:only-child {
      margin-left: auto;
    }

    button {
      border-radius: 80px;
    }
  }
}

.activation {
  // centered title with a single red button
  .title {
    text-align: center;
  }

  p {
    max-width: 650px;
  }

  .content {
    padding: 0 100px;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* common style used across all or most modals */
.modalFormFieldset {
  border: 0;
}

.modalFormLegend {
  color: $blue;
  font-size: $typography-size-large;
}

.modalContentWrapper {
  margin: 0 auto;
  padding: $layout-default-padding;

  :global .input-label-text {
    @include visually-hidden();

    @include media(">=tablet") {
      @include revert-visually-hidden();
      margin-bottom: $layout-base-gap;
    }
  }
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: $layout-base-gap;
}

.buttonRow {
  background-color: $color-neutral-inverted;
  bottom: 0;
  box-shadow: $box-shadow-button-row;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: $layout-default-padding;
  position: fixed;
  text-align: center;
  width: 100%;

  @include media(">=tablet") {
    background-color: transparent;
    bottom: initial;
    box-shadow: none;
    left: initial;
    margin: 0 auto;
    max-width: max-content;
    padding: $layout-default-padding 0;
    position: initial;
  }
}

.buttonRowStacked {
  display: block;
}
