
          @import "src/sass/global";
        
.close {
  background: transparent;
  border: none;
  position: inherit;
  color: $black;
  top: 0;
  right: 0;
  line-height: 1;
  padding: 0;
  height: rem(45px);
  width: rem(45px);

  &:hover {
    background-color: $gray-150;
    border-radius: 100%;
  }
}
