
          @import "src/sass/global";
        
.wrap {
  position: relative;
}

.toggleButton {
  border: none;
  background: none;
  position: absolute;
  height: 100%;
  top: 2px;
  right: 10px;
}
