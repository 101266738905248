
          @import "src/sass/global";
        
.modalHeader {
  background-color: $color-gray-05;
  border-bottom: 1px solid $color-gray-30;
  box-shadow: $box-shadow-header-row;
  display: flex;
  justify-content: space-between;
  padding: $layout-base-padding;
}

.modalTitleContainer {
  display: flex;
  gap: $layout-default-padding;
}

.title {
  margin: 0;
}
