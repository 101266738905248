
          @import "src/sass/global";
        
.stepsNavigation {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: $layout-base-gap;
  justify-content: center;
  margin-top: $layout-base-gap-medium;
}
