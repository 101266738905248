.servicesIconsContainer {
  flex: 1 1 100%;

  :global .bathroom,
  :global .bedroom,
  :global .custom,
  :global .entrance,
  :global .exterior,
  :global .general,
  :global .kitchen,
  :global .lighting,
  :global .stairs,
  :global .storage {
    align-items: center;
    display: inline-flex;
    gap: $layout-narrow-gap;

    &::before {
      background-color: $color-blue-70;
      content: '';
      display: block;
      flex: 0 0 3rem;
      height: 3rem;
      mask-size: 3rem;
    }
  }

  :global .bathroom {
    &::before {
      mask-image: url('../../images/services/bathroom-modifications.svg');
    }
  }

  :global .bedroom {
    &::before {
      mask-image: url('../../images/services/bedroom-modifications.svg');
    }
  }

  :global .custom {
    &::before {
      mask-image: url('../../images/services/custom-modifications.svg');
    }
  }

  :global .exterior {
    &::before {
      mask-image: url('../../images/services/exterior-modifications.svg');
    }
  }

  :global .entrance {
    &::before {
      mask-image: url('../../images/services/entrance-modifications.svg');
    }
  }

  :global .general {
    &::before {
      mask-image: url('../../images/services/home-assessment.svg');
    }
  }

  :global .kitchen {
    &::before {
      mask-image: url('../../images/services/kitchen-modifications.svg');
    }
  }

  :global .lighting {
    &::before {
      mask-image: url('../../images/services/lighting-modifications.svg');
    }
  }

  :global .stairs {
    &::before {
      mask-image: url('../../images/services/stair-modifications.svg');
    }
  }

  :global .storage {
    &::before {
      mask-image: url('../../images/services/storage-modifications.svg');
    }
  }
}
