
          @import "src/sass/global";
        
.success {
  background-color: $color-neutral-inverted;
  height: 100%;
  padding: $layout-base-gap;
  text-align: center;
}

.successTitle {
  font-size: $typography-size-large;
}

.proposalSuccessMessage {
  font-size: $typography-size-large;
  line-height: $typography-line-height-narrow;
}
